import { Get } from "./request"

export const getPatient = async (patientId, userId) => {
  const res = await Get("/patients", {
    queryStringParameters: {
      user_id: userId,
      patient_id: patientId
    },
  })

  return res
}