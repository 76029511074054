import React from "react";
import PropTypes from "prop-types";
import { Layout, Row, Col, Menu, Dropdown, Avatar, Icon } from "antd";
import { Link, withRouter } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { getUser } from "./User";
import { clear_values } from "./db";
const { Header } = Layout;

function ArchFormHeader(props) {
  const user = Amplify.Cache.getItem("user");
  return (
    <Header className="header">
      <Row gutter={{ xs: 0, lg: 32 }}>
        <Col xs={12} lg={{ span: 4 }}>
          <Link to="/" className="logo" />
        </Col>
        <Col xs={12} lg={{ span: 10, offset: 8 }} style={{ height: "100%" }}>
          {props.showNav && (
            <Menu
              theme="light"
              mode="horizontal"
              selectedKeys={[props.location.pathname]}
              style={{
                display: "flex",
                justifyContent: "space-between",
                lineHeight: "64px",
                height: "100%",
                borderBottom: "none",
              }}
            >
              <Menu.Item key="/">
                <Link to={"/"}>Patients</Link>
              </Menu.Item>
              <Menu.Item key="/settings">
                <Link to={"/settings"}>Settings</Link>
              </Menu.Item>
            </Menu>
          )}
        </Col>
        <Col
          xs={12}
          lg={{ span: 2 }}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Dropdown
            overlay={
              <Menu
                onClick={({ item, key, keyPath }) => {
                  if (key === "login") {
                    clear_values();
                    Amplify.Cache.clear();
                    Amplify.Auth.signOut();
                  }
                }}
              >
                <Menu.Item key="profile">
                  <Link to="/profile">Profile</Link>
                </Menu.Item>
                <Menu.Item key="login">
                  <Link to="/login">Logout</Link>
                </Menu.Item>
              </Menu>
            }
          >
            {!props.hideUser ? (
              <div>
                <Avatar>
                  {user.name.split(" ").length > 1
                    ? user.name.split(" ")[0][0] + user.name.split(" ")[1][0]
                    : user.name[0]}
                </Avatar>{" "}
                <Icon type="down" />
              </div>
            ) : (
              <div />
            )}
          </Dropdown>
        </Col>
      </Row>
    </Header>
  );
}

ArchFormHeader.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(ArchFormHeader);
