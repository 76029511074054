import { API } from "aws-amplify"
import { processConfig } from "../config/env"

const ORDER_API_NAME = processConfig.env.BACK_API_NAME

export const Get = async (path, params) => {
  const res = await API.get(ORDER_API_NAME, path, params)
  return res
}

export const Post = async (path, body) => {
  const res = await API.post(ORDER_API_NAME, path, { body })
  return res
}

export const Put = async (path, body) => {
  const res = await API.put(ORDER_API_NAME, path, { body })
  return res
}

export const Delete = async (path, params) => {
  const res = await API.del(ORDER_API_NAME, path, params)
  return res
}
