import React, { useEffect, useState, useCallback } from "react";
import { useAppViewCtrl } from "../container/app-view-state";
import { Card, makeStyles, Button } from "@material-ui/core";
import { Slider } from "../components/Mobile-Slider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPause } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";
import { PrevIcon, BackwardIcon, ForwardIcon, NextIcon } from "../images/icons";
import Modal from "@material-ui/core/Modal";
import Checkbox from '@material-ui/core/Checkbox';
import { Amplify } from "aws-amplify";
import { getUser, updateUser } from "../../User";

const styles = makeStyles({
  controlContainer: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    left: "50%",
    transform: "translateX(-50%)",
  },
  card: {
    height: 106,
    bottom: 8,
    display: "flex",
    alignItems: "center",
    borderRadius: 10,
    boxShadow: "none",
    minWidth: 400,
  },
  cardMobile: {
    width: "300px !important",
    alignItems: "center",
    borderRadius: 10,
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    height: 36,
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    borderRadius: 16,
    marginBottom: 10,
  },
  steps: {
    display: "flex",
    flexDirection: "row",
    height: "70px",
    boxSizing: "border-box",
    margin: "0px 15px",
    color: "#444444",
    userSelect: "none",
    width: "100%",
    justifyContent: "space-between",
  },
  verticalCenter: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
  },
  step: {
    padding: ".25em 1em",
    margin: ".1em",
    background: "#e9e9e9",
    color: "#999999",
    borderRadius: "2px",
    userSelect: "none",
  },
  stepClicked: {
    padding: ".25em 1em",
    margin: ".1em",
    background: "#e4f1ff",
    color: "#444444",
    borderRadius: "2px",
    border: "1px solid #97c7ff",
    userSelect: "none",
  },
  stepHover: {
    padding: ".25em 1em",
    margin: ".1em",
    background: "#e9e9e9",
    color: "#444444",
    borderRadius: "2px",
    cursor: "pointer",
  },
  faCircle: {
    width: 6,
    height: 16,
    borderRadius: 20,
    backgroundColor: "#dcdde0",
    color: "#caccce",
    margin: 8,
  },
  faCircleHover: {
    width: 6,
    height: 16,
    borderRadius: 20,
    backgroundColor: "#dcdde0",
    opacity: ".5",
    marginRight: "1px",
  },
  faCircleClicked: {
    width: 6,
    height: 16,
    borderRadius: 20,
    backgroundColor: "#8a8f98",
    color: "#caccce",
    margin: 8,
  },
  stepNum: {
    fontSize: "smaller",
  },
  overlay: {
    height: "20px",
    textAlign: "center",
    cursor: "pointer",
  },
  dot: {
    height: "15px",
    width: "15px",
    backgroundColor: "#66a3ff",
    borderRadius: "50%",
    display: "inline-block",
    marginTop: "5px",
  },
  loading: {
    position: "absolute",
    top: "50%",
    left: "calc(50% - 150px)",
    width: "300px",
    display: "flex",
    justifyContent: "center",
  },
  loadingText: {
    marginTop: "1em",
  },
  modal: {
    zIndex: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: "white",
    borderRadius: 10,
    boxShadow: "none",
    lineHeight: 2,
    padding: "25px 32px 31px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: 20,
    width: '750px',
  },
  title: {
    fontSize: '24px',
    fontWeight: '600',
    lineHeight: '125%',
    color:'#111827'
  },
  time: {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '125%',
    color: '#111827',
    marginBottom: '16px',
    marginTop: '4px'
  },
  text: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '24px',
    color: '#111827',
    listStyle: 'none',
    margin: 0,
    padding: 0
  },
  button: {
    backgroundColor: "#0076ff", 
    color: "#ffffff",
    width: '82px',
    height: '38px',
    fontSize: '14px',
    fontWeight: '500',
  },
  noticeCheckbox: {
    display: "flex",
    alignItems: "center",
    margin:'9px 0 32px -12px',
    height: '24px',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '24px',
    color: '#111827',
  }
});

// const sizeToPlatform = [
//   17 + Math.floor((window.innerWidth - 1024) / 60),
//   16,
//   13,
//   12,
// ];

const Controls = ({ interfaceType }) => {
  const {
    isFirstStepLoaded,
    currentStep,
    setCurrentStep,
    readyState,
    totalStep,
    playAnimation,
    setPlayAnimation,
    isMobileView,
    setIsMobileView,
    // overlayStep,
    // setOverlayStep,
  } = useAppViewCtrl();

  const classes = styles();
  const [sizeLevel, setSizeLevel] = useState(0);
  const [mousedown, setMousedown] = useState(false);
  const [open, setOpen] = useState(false);
  const [noticeChecked, setNoticeChecked] = useState(false)
  const [showNotice, setShowNotice] = useState(true)

  /*
      0: size > 1024
      1: 1024 > size > 992
      2: 992 > size > 768
      3: 768 > size
    */

  useEffect(() => {
    getUser().then((user) => {
      // In order to adapt to the hidden checkbox on the page, set the value of display_notice to the opposite
      if (user && user.attributes["custom:display_notice"]) {
        const flag = user.attributes["custom:display_notice"] !== "True"
        setShowNotice(flag)
        setOpen(flag)
      }
    });

    const resize = () => {
      if (isMobile) {
        setIsMobileView(true);
      } else {
        const width = window.innerWidth;
        if (width >= 1024) {
          if (sizeLevel !== 0) setSizeLevel(0);
        } else if (width >= 992) {
          if (sizeLevel !== 1) setSizeLevel(1);
        } else if (width >= 768) {
          if (sizeLevel !== 2) setSizeLevel(2);
        } else if (sizeLevel !== 3) setSizeLevel(3);
        if (width < 768) setIsMobileView(true);
        else setIsMobileView(false);
      }
    };

    const pointerdown = () => {
      setMousedown(true);
    };

    const pointerup = () => {
      setMousedown(false);
    };

    resize();

    window.addEventListener("resize", resize, false);
    window.addEventListener("pointerdown", pointerdown, false);
    window.addEventListener("pointerup", pointerup, false);
    return () => {
      window.removeEventListener("resize", resize);
      window.removeEventListener("pointerdown", pointerdown);
      window.removeEventListener("pointerup", pointerup);
    };
  }, []);

  const handleOk = async () => {
    setOpen(false)
    try {
      if(noticeChecked) {
        // To hidden notice: In order to adapt to the hidden checkbox on the page, set the value of display_notice to the opposite
        await updateUser({
          "custom:display_notice": "True"
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  const handleNumbers = (index, action) => {
    if (!isFirstStepLoaded) return;
    if (index !== totalStep - 1 && index >= readyState) return;
    if (action === "click" || (action === "drag" && mousedown)) {
      setCurrentStep(() => index + 1);
      // setOverlayStep(() => Math.min(totalStep, index + 2));
    }
  };

  const onPrevious = () => {
    if (currentStep < 2) return;
    setCurrentStep(() => currentStep - 1);
    // setOverlayStep(() => currentStep);
  };

  const onPlay = useCallback(() => {
    setPlayAnimation(!playAnimation);
  }, [playAnimation, setPlayAnimation]);

  const onNext = () => {
    if (currentStep === totalStep) return;
    setCurrentStep(() => currentStep + 1);
    // setOverlayStep(() => Math.min(totalStep, currentStep + 2));
  };

  const onForward = () => {
    setCurrentStep(() => totalStep);
    // setOverlayStep(() => totalStep);
  };

  const onBackward = () => {
    setCurrentStep(() => 1);
    // setOverlayStep(() => 2);
  };

  if (!isFirstStepLoaded) {
    return (
      <Card className={classes.loading}>
        <p className={classes.loadingText}>Loading patient, please wait</p>
      </Card>
    );
  }

  return (
    <div
      className={classes.controlContainer}
      style={isMobileView ? { bottom: "10px" } : { bottom: "40px" }}
    >
      {!interfaceType && showNotice && (
        <Modal
          open={open}
          className={classes.modal}
        >
          <div className={classes.modalContent}>
            <div className={classes.title}>Notice</div>
            <div className={classes.time}>Dec 02, 2024</div>
            <ul className={classes.text}>
              <li>Current 3D web viewer does not support separated staging for upper & lower arches.</li>
              <li>Recommend upgrading to ArchForm Desktop Release-v2.4.0 to avoid potential compatibility issues.</li>
              <li>Please double-check patient data on ArchForm Desktop app before sharing with third-party.</li>
              <li>Please contact ArchForm Support if you run into any issues.</li>
              <div className={classes.noticeCheckbox}>
                <Checkbox
                  sx={{
                    "&:hover": { bgcolor: "transparent" },
                  }}
                  disableRipple
                  color="default"
                  inputProps={{ "aria-label": "Checkbox demo" }}
                  checked={noticeChecked}
                  onChange={(e) => setNoticeChecked(e.target.checked)}
                  size="small"
                />
                <span>Don't show this message again.</span>
              </div>
            </ul>
            <Button variant="contained" onClick={() => handleOk()} className={classes.button}>OK</Button>
          </div>
        </Modal>
      )}
      <div className={classes.buttons}>
        <Button
          disableRipple
          style={{
            backgroundColor: "#F3F4F5",
            borderRadius: "14px 4px 4px 14px",
            width: 34,
            height: 28,
            minWidth: 28,
            margin: isMobileView ? "4px 2px 4px 4px" : "4px 2px",
          }}
          onClick={onBackward}
        >
          <PrevIcon />
        </Button>
        <Button
          disableRipple
          style={{
            backgroundColor: "#F3F4F5",
            borderRadius: 4,
            width: 34,
            height: 28,
            minWidth: 28,
            margin: "4px 2px",
          }}
          onClick={onPrevious}
        >
          <BackwardIcon />
        </Button>
        <Button
          disableRipple
          style={{
            backgroundColor: "#F3F4F5",
            borderRadius: 4,
            width: 34,
            height: 28,
            minWidth: 28,
            margin: "4px 2px",
          }}
          onClick={onPlay}
        >
          <FontAwesomeIcon
            icon={playAnimation ? faPause : faPlay}
            color="rgba(138, 143, 152, 1)"
            size="sm"
          />
        </Button>
        <Button
          disableRipple
          style={{
            backgroundColor: "#F3F4F5",
            borderRadius: 4,
            width: 34,
            height: 28,
            minWidth: 28,
            margin: "4px 2px",
          }}
          onClick={onNext}
        >
          <ForwardIcon />
        </Button>
        <Button
          disableRipple
          style={{
            backgroundColor: "#F3F4F5",
            borderRadius: "4px 14px 14px 4px",
            width: 34,
            height: 28,
            minWidth: 28,
            margin: isMobileView ? "4px 4px 4px 2px" : "4px 2px",
          }}
          onClick={onForward}
        >
          <NextIcon />
        </Button>
      </div>
      {isMobileView ? (
        <Slider
          className={classes.cardMobile}
          value={currentStep - 1}
          currentStep={currentStep}
          onChange={(val) => handleNumbers(val, "click")}
          marks
          min={0}
          max={totalStep - 1}
        />
      ) : (
        <Card className={classes.card}>
          <div className={classes.steps}>
            {new Array(totalStep).fill(0).map((item, index) => (
              <div
                key={index}
                style={{
                  textAlign: "center",
                  flexDirection: "column",
                }}
                onClick={() => handleNumbers(index, "click")}
                onMouseOver={() => handleNumbers(index, "drag")}
              >
                <div
                  style={{
                    padding: "2px 0",
                    borderRadius: 6,
                    backgroundColor:
                      currentStep === index + 1 ? "#dcdde0" : "white",
                  }}
                >
                  <div
                    className={
                      currentStep === index + 1
                        ? classes.faCircleClicked
                        : classes.faCircle
                    }
                  />
                  <div
                    key={index}
                    className={
                      currentStep === index + 1
                        ? classes.faCircleClicked
                        : classes.faCircle
                    }
                  />
                </div>
                <span
                  className={classes.stepNum}
                  style={{
                    visibility:
                      currentStep === index + 1 || index % 5 === 0
                        ? "visible"
                        : "hidden",
                  }}
                >
                  {index}
                </span>
              </div>
            ))}
          </div>
        </Card>
      )}
    </div>
  );
};
export default Controls;
