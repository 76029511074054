import React from "react";
import { Amplify } from "aws-amplify";
import { Radio, Spin } from "antd";
import { Loader } from "@googlemaps/js-api-loader";
import { TeethTable } from "./PatientView";
import {
  getUser,
  updateUser,
  getBillingPortal,
  getOrderHistory,
  getBillingAccount,
  getCheckout,
  archform_plans,
  getDefaultSource,
  getSubscriptions,
} from "./User";
import "./SettingsPage.css";

class BillingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      updatingUser: true,
      billing_cycle: "month",
      orders: [],
    };

    getUser().then((user) => this.setState({ user }));
    this.handleStripeCheckout = this.handleStripeCheckout.bind(this);
    this.updateUserAttribute = this.updateUserAttribute.bind(this);

    getBillingAccount().then((billing) => {
      this.setState({ billing });
      if (!billing || !billing.subscriptions.total_count)
        this.setState({ hasSubscription: false });
      else this.setState({ hasSubscription: true });
      this.setState({ updatingUser: false });
      getDefaultSource().then((defaultSource) =>
        this.setState({ defaultSource })
      );
    });
    // getOrderHistory()
    //   .then(orders => {
    //     this.setState({orders})
    //   })
  }

  componentDidMount() {
    getUser().then((user) => {
      this.setState({ user });
      try {
        const address = JSON.parse(user.attributes.address);
        this.setState({ address });
      } catch (e) {}
    });
  }

  handleStripeCheckout = (productId) => async () => {
    this.setState({ updatingUser: true });
    const checkout_page = await getCheckout(productId);
    if (checkout_page) {
      this.setState({ updatingUser: true });
      window.location = checkout_page;
    }
  };

  async updateUserAttribute(attribute) {
    this.setState({ updatingUser: true });
    await updateUser(attribute);
    const user = await getUser();
    this.setState({ user, updatingUser: false });
  }

  render() {
    const { user, billing, defaultSource } = this.state;

    return (
      <Spin
        style={{ position: "fixed" }}
        spinning={!user || this.state.updatingUser}
      >
        <div className="settings-page-container">
          <div className="settings-layout-container">
            <p className="settings-title">Billing</p>

            {defaultSource &&
            (defaultSource.dynamic_last4 || defaultSource.last4) ? (
              <>
                <div className="settings-row" style={{ width: "400px" }}>
                  <p className="settings-subtitle">Payment Method</p>
                  <p>
                    {`Card ending in 
                    ${
                      defaultSource.dynamic_last4
                        ? defaultSource.dynamic_last4
                        : defaultSource.last4
                    }, 
                    expires ${defaultSource.exp_month}/${
                      defaultSource.exp_year
                    }`}
                  </p>
                  <button
                    className="logout-button"
                    onClick={(async (e) => {
                      this.setState({ updatingUser: true });
                      const billing_portal = await getBillingPortal();
                      this.setState({ patientsLoaded: false });
                      window.location = billing_portal + "/payment-methods";
                    }).bind(this)}
                  >
                    Add New Card
                  </button>
                </div>
              </>
            ) : null}

            <div className="settings-row" style={{ width: "400px" }}>
              {/* <p className="settings-subtitle">Billing</p> */}
            </div>
            {billing &&
            billing.subscriptions &&
            billing.subscriptions.data.length === 0 ? (
              <>
                <div
                  className="settings-row subscription-info"
                  style={{ width: "400px" }}
                >
                  <p>You don't currently have any active software plans.</p>
                  <p>
                    You can learn more about them at{" "}
                    <a target="blank" href="https://www.archform.co/pricing">
                      archform.com
                    </a>
                  </p>
                  <p>
                    For newly registered accounts, $200 credits will be offered
                    after completing the onboarding call.
                  </p>
                  <p>
                    Please{" "}
                    <a href="mailto:info@archform.co?subject=Enterprise%20Quote%20Request">
                      contact us{" "}
                    </a>
                    if you export more than 35 patients a month
                  </p>
                </div>
                {/* 
                <Radio.Group
                  onChange={(e) =>
                    this.setState({ billing_cycle: e.target.value })
                  }
                  defaultValue={this.state.billing_cycle}
                >
                  <Radio.Button value={"month"}>Bill Monthly</Radio.Button>
                  <Radio.Button value={"year"}>Bill Yearly</Radio.Button>
                </Radio.Group> */}

                <p className="settings-subtitle" style={{ marginTop: "20px" }}>
                  Software Subscription
                </p>
                <div
                  className="settings-row product-selection-container"
                  style={{ justifyContent: "flex-start" }}
                >
                  <div className="product-info-container">
                    <div className="product-title-container">
                      <p className="product-title-name">Pay As You Go</p>
                      {/* <p className="production-selection-description">${`${archform_plans.Patient[this.state.billing_cycle].price}/mo`}</p> */}
                    </div>
                    <div className="product-benefits">
                      <p>$50 Per Case</p>
                    </div>

                    <div
                      className="product-expand-button"
                      onClick={this.handleStripeCheckout(
                        archform_plans.Patient[this.state.billing_cycle]
                          .stripeid
                      )}
                    >
                      <p>Subscribe</p>
                    </div>
                  </div>
                  <div className="product-info-container">
                    <div className="product-title-container">
                      <p className="product-title-name">Pro</p>
                      <p className="production-selection-description">
                        $
                        {`${archform_plans.Pro[
                          this.state.billing_cycle
                        ].price.toLocaleString("en-US")} Pro/mo`}
                      </p>
                    </div>
                    <div className="product-benefits">
                      <p>Unlimited Cases</p>
                    </div>
                    <div
                      className="product-expand-button"
                      onClick={this.handleStripeCheckout(
                        archform_plans.Pro[this.state.billing_cycle].stripeid
                      )}
                    >
                      <p>Subscribe</p>
                    </div>
                  </div>
                  {/* <div className="product-info-container">
                    <div className="product-title-container">
                      <p className="product-title-name">Basic</p>
                      <p className="production-selection-description">
                        $
                        {`${
                          archform_plans.Basic[this.state.billing_cycle].price
                        }/mo`}
                      </p>
                    </div>
                    <div className="product-benefits">
                      <p>5 Cases</p>
                    </div>

                    <div
                      className="product-expand-button"
                      onClick={this.handleStripeCheckout(
                        archform_plans.Basic[this.state.billing_cycle].stripeid
                      )}
                    >
                      <p>Subscribe</p>
                    </div>
                  </div>
                  <div className="product-info-container">
                    <div className="product-title-container">
                      <p className="product-title-name">Premium</p>
                      <p className="production-selection-description">
                        $
                        {`${
                          archform_plans.Premium[this.state.billing_cycle].price
                        }/mo`}
                      </p>
                    </div>
                    <div className="product-benefits">
                      <p>10 Cases</p>
                    </div>

                    <div
                      className="product-expand-button"
                      onClick={this.handleStripeCheckout(
                        archform_plans.Premium[this.state.billing_cycle]
                          .stripeid
                      )}
                    >
                      <p>Subscribe</p>
                    </div>
                  </div> 
                  <div className="product-info-container">
                    <div className="product-title-container">
                      <p className="product-title-name">Pro</p>
                      <p className="production-selection-description">
                        $
                        {`${
                          archform_plans.Pro[this.state.billing_cycle].price
                        }/mo`}
                      </p>
                    </div>
                    <div className="product-benefits">
                      <p>25 Cases</p>
                    </div>
                    <div
                      className="product-expand-button"
                      onClick={this.handleStripeCheckout(
                        archform_plans.Pro[this.state.billing_cycle].stripeid
                      )}
                    >
                      <p>Subscribe</p>
                    </div>
                  </div> */}
                </div>
                {/* <p className="settings-subtitle" style={{ marginTop: "20px" }}>
                  Form Aligners and Setup Services
                </p> */}

                {/* <p>Form Aligners and Setup Services</p> */}
                {/* <div className="settings-row product-selection-container">
                  <div className="product-info-container">
                    <div className="product-title-container">
                      <p className="product-title-name">Pay As You Go</p>
                    </div>
                    <div className="product-benefits"></div>

                    <div
                      className="product-expand-button"
                      onClick={this.handleStripeCheckout(
                        archform_plans.Patient[this.state.billing_cycle]
                          .stripeid
                      )}
                    >
                      <p>Subscribe</p>
                    </div>
                  </div>
                </div> */}
              </>
            ) : billing &&
              billing.subscriptions &&
              billing.subscriptions.data.length > 0 ? (
              <div className="settings-row" style={{ width: "400px" }}>
                <p>
                  You can update your billing details and subscription plan here
                </p>

                <button
                  className="logout-button"
                  onClick={(async (e) => {
                    this.setState({ updatingUser: true });
                    const billing_portal = await getBillingPortal();
                    this.setState({ patientsLoaded: false });
                    window.location = billing_portal;
                  }).bind(this)}
                >
                  View Subscriptions
                </button>
                {/* <button className="logout-button"
                    style={{ marginTop: '5px' }}
                    onClick={(async e => {
                      if (billing && billing.subscriptions) {
                        if (billing.subscriptions.data.length == 0) {
                          const checkout_page = await getCheckout('price_1J0tQmIffkoglhLDen3rNdnt');
                          if (checkout_page) window.location = checkout_page;
                        }
                      }
                      // const billing_portal = await getBillingPortal();
                      // window.location = billing_portal;
                    }).bind(this)}>View Plans
                  </button> */}
              </div>
            ) : null}
            {/* <div className="settings-row" style={{ width: '400px' }}>
              <p className="settings-subtitle">Orders</p>
            </div> */}
            {
              // this.state.orders.map(order => (
              //   <div className='order-container'>
              //     <p>{order.name}</p>
              //     <p>{order.status}</p>
              //     <p>{order.service}</p>
              //   </div>
              // ))
            }
          </div>
        </div>
      </Spin>
    );
  }
}

export default BillingPage;
